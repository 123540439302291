<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <el-form ref="form" :model="formData" label-width="120px">
      <el-form-item label="品牌" prop="goodsBrand" :rules="rules">
        <el-select
          style="width: 400px"
          v-model="formData.goodsBrand"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option
            v-for="item in brandList"
            :key="item.enumCode"
            :label="item.enumText"
            :value="item.enumCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="车型" prop="model" :rules="rules">
        <el-select
          style="width: 400px"
          v-model="formData.model"
          placeholder="请选择"
          size="small"
          clearable
        >
          <el-option
            v-for="item in modelList"
            :key="item.enumCode"
            :label="item.enumText"
            :value="item.enumCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="颜色" prop="colorList" :rules="rules">
        <el-select
          style="width: 400px"
          v-model="formData.colorList"
          placeholder="请选择"
          size="small"
          multiple
          clearable
        >
          <el-option
            v-for="item in colorList"
            :key="item.enumText"
            :label="item.enumText"
            :value="item.enumCode"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="封面图" prop="coverImage" :rules="rules">
        <FileUpload
          :name.sync="formData.coverImage"
          :url="formData.coverImageUrl"
        ></FileUpload>
      </el-form-item>
      <el-form-item label="官网价" prop="officialPrice" :rules="rules">
        <el-input-number
          style="width: 400px"
          size="small"
          v-model="formData.officialPrice"
          :precision="2"
          :step="0.01"
          :min="0"
        ></el-input-number>
      </el-form-item>
      <el-form-item label="全新售价" prop="minPrice" :rules="rules">
        <div>
          <el-input-number
            style="width: 190px"
            size="small"
            v-model="formData.minPrice"
            :precision="2"
            :step="0.01"
            :min="0"
          ></el-input-number>
          ~
          <el-input-number
            style="width: 190px"
            size="small"
            v-model="formData.maxPrice"
            :precision="2"
            :step="0.01"
            :min="0"
          ></el-input-number>
        </div>
      </el-form-item>
      <el-form-item label="买断金" prop="buyoutPrice" :rules="rules">
        <el-input-number
          style="width: 400px"
          size="small"
          v-model="formData.buyoutPrice"
          :precision="2"
          :step="0.01"
          :min="0"
        ></el-input-number>
      </el-form-item>

      <el-form-item label="">
        <el-button type="primary" size="small" @click="save">保存</el-button>
        <el-button type="" size="small" @click="$router.back()">取消</el-button>
      </el-form-item>
    </el-form>
    <!--  -->
    <el-dialog title="位置" :visible.sync="dialogVisible" width="width">
      <template>
        <GdMap
          ref="map"
          :lng.sync="formData.lng"
          :lat.sync="formData.lat"
        ></GdMap>
      </template>
      <div slot="footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false" size="small"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <div style="height: 50px"></div>
  </div>
</template>

<script>
import {
  getDetailAPI,
  editAPI,
  addAPI,
  getBrandListAPI,
  getAttrListAPI,
} from "./api";
import { EnumGoodsType, EnumGoodsGroupCode } from "@/enum/dict/index.js";
import FileUpload from "@/components/file-upload/index.vue";
import SelectDict from "@/views/components/select-dict.vue";

export default {
  name: "StoreGoods2AddOrEdit",
  components: { SelectDict, FileUpload },
  data() {
    return {
      dialogVisible: false,
      rules: [{ required: true, message: "该项不能为空", trigger: "blur" }],
      brandList: [],
      modelList: [],
      colorList: [],
      formData: {
        goodsType: 2,
        goodsBrand: "",
        model: "",
        memory: "",
        colorList: [],
        coverImage: "",
        officialPrice: "",
        minPrice: "",
        maxPrice: "",
        oldMinPrice: "",
        oldMaxPrice: "",
        buyoutPrice: "",
      },
    };
  },

  mounted() {
    this.getBrandList();
    this.getModelList();
    this.getColorList();
    if (this.$route.params.id) this.getGoodsDetail();
  },

  methods: {
    async getBrandList() {
      this.brandList = await getBrandListAPI({
        goodsType: EnumGoodsType.ELECTRIC,
      });
    },
    async getModelList() {
      let params = {
        goodsType: EnumGoodsType.ELECTRIC,
        groupCode: EnumGoodsGroupCode.MODEL,
      };
      const res = await getAttrListAPI(params);
      this.modelList = res || [];
    },
    async getColorList() {
      let params = {
        goodsType: EnumGoodsType.ELECTRIC,
        groupCode: EnumGoodsGroupCode.COLOR,
      };
      const res = await getAttrListAPI(params);
      this.colorList = res || [];
    },
    save() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(this.formData).then(() => {
              this.$message.success("编辑成功");
              this.$router.back();
            });
          } else {
            addAPI(this.formData).then(() => {
              this.$message.success("新增成功");
              this.$router.back();
            });
          }
        }
      });
    },
    async getGoodsDetail() {
      this.formData = await getDetailAPI(this.$route.params.id);
    },
    showMap() {
      this.dialogVisible = true;
      this.$nextTick(() => {
        this.$refs.map.tipinput = "";
        setTimeout(() => {
          this.$refs.map.initMap();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper /deep/ {
  width: 100%;

  /* switch按钮样式 */
  .switch .el-switch__label {
    position: absolute;
    display: none;
    color: #fff !important;
  }
  /*打开时文字位置设置*/
  .switch .el-switch__label--right {
    z-index: 1;
  }
  /* 调整打开时文字的显示位子 */
  .switch .el-switch__label--right span {
    margin-left: 5px;
  }
  /*关闭时文字位置设置*/
  .switch .el-switch__label--left {
    z-index: 1;
  }
  /* 调整关闭时文字的显示位子 */
  .switch .el-switch__label--left span {
    margin-left: 20px;
  }
  /*显示文字*/
  .switch .el-switch__label.is-active {
    display: block;
  }
  /* 调整按钮的宽度 */
  .switch.el-switch .el-switch__core,
  .el-switch .el-switch__label {
    width: 55px !important;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
.wrapper /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 148px;
    height: 148px;
    line-height: 148px;
    text-align: center;
  }
  .avatar {
    width: 148px;
    height: 148px;
    display: block;
  }
}
</style>
